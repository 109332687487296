import React from 'react';
import styled, { css } from 'styled-components';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import {Section} from 'src/components/molecules/Section';
import {Grid, Column} from 'src/components/molecules/Grid';
import {Link} from "gatsby";
import {InlineList} from 'src/components/molecules/InlineList';
import {FixedImg} from 'src/embeds/image/image-dato';
import {Card} from "src/components/atoms/Card";

const CalloutHeader = styled.h3`
    ${({ customTextColor }) => customTextColor && css`
        color: ${customTextColor}
    `}
`

export default function Drawer({heading, content, contentNode, callouts, ctas, customData}) {
    callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
    customData = JSON.parse(customData);
    const calloutFlags = customData?.flags?.calloutFlags;
    return (
        <MDXProvider components={MDXComponents}>
            <Section {...customData?.flags}>
                <h2>{heading}</h2>
                {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
                {callouts.length > 0 ? (
                    <Grid
                        columns={`repeat(${Math.min(callouts.length, 2)}, minmax(0, 1fr))`}
                        spacious
                        style={{marginTop: 80}}
                    >
                        {callouts.map(({ctas, heading, content, contentNode, media}, i) => {
                            return (
                                <Card key={i} {...calloutFlags}>
                                    {media && (
                                        <FixedImg
                                            css={`
                        margin-bottom: 20px;
                      `}
                                            data={media}
                                            width={media.width}
                                            role="presentation"
                                        />
                                    )}
                                    <CalloutHeader {...calloutFlags}>{heading}</CalloutHeader>
                                    {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
                                    {ctas.length > 0 && (
                                        <InlineList style={{width: '100%'}}>
                                            {ctas.map((cta, i) => {
                                                const ctaFlags = i > 0 ? {secondary: true} : {};
                                                return (
                                                    <li key={i}>
                                                        <Link to={cta.url} {...ctaFlags}>
                                                            {cta.text}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </InlineList>
                                    )}
                                </Card>
                            );
                        })}
                    </Grid>
                ) : null}
            </Section>
        </MDXProvider>
    );
}
